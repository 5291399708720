import { serviceShopapi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getCards = (cancelToken?: CancelToken) =>
    serviceShopapi.get('/cards', {
        cancelToken,
    })

export const createCard = (data: any, cancelToken?: CancelToken) =>
    serviceShopapi.post('/cards/session-setup', data, {
        cancelToken,
    })

export const deleteCard = (cardId: string, cancelToken?: CancelToken) =>
    serviceShopapi.delete(`/cards/${cardId}`, {
        cancelToken,
    })

export const setDefaultCard = (cardId: string, cancelToken?: CancelToken) =>
    serviceShopapi.patch(`/cards/${cardId}/set-default`, {
        cancelToken,
    })
