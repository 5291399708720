





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'

@Component({
    name: 'VsPaymentMethodCard',
    components: {
        VsListingCard,
        VsDropdownButton,
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private card!: any

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) private loading?: boolean

    get cardName () {
        return this.card.card.brand.toUpperCase()
    }

    get hasCardImage () {
        return this.cardName === 'VISA' || this.cardName === 'MASTERCARD' || this.cardName === 'MAESTRO' || this.cardName === 'AMEX'
    }

    private editCards () {
        this.$emit('edit')
    }
}
