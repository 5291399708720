

























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsCartLineItem',
})
export default class extends Vue {
    @Prop({ default: false, required: false, type: Boolean }) labelBold!: boolean
    @Prop({ default: false, required: false, type: Boolean }) textBold!: boolean
    @Prop({ default: false, required: false, type: Boolean }) showQuantity!: boolean
    @Prop({ default: 'medium', required: false }) size!: string
    @Prop({ default: '', required: false }) text!: string
    @Prop({ default: '', required: false }) label!: string
    @Prop({ default: '', required: false }) quantity!: string

    get textClasses () {
        return {
            'vs-body-small': this.size === 'small' && !this.textBold,
            'vs-body-medium': this.size !== 'large' && this.size !== 'small' && !this.textBold,
            'vs-body-large': this.size === 'large' && !this.textBold,
            'vs-h100': this.size === 'small' && this.textBold,
            'vs-h200': this.size !== 'large' && this.size !== 'small' && this.textBold,
            'vs-h300': this.size === 'large' && this.textBold,
        }
    }

    get labelClasses () {
        return {
            'vs-body-small': this.size === 'small' && !this.labelBold,
            'vs-body-medium': this.size !== 'large' && this.size !== 'small' && !this.labelBold,
            'vs-body-large': this.size === 'large' && !this.labelBold,
            'vs-h100': this.size === 'small' && this.labelBold,
            'vs-h200': this.size !== 'large' && this.size !== 'small' && this.labelBold,
            'vs-h300': this.size === 'large' && this.labelBold,
        }
    }
}
